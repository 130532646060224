import React, { useEffect, useState } from 'react'
import { CModal, CModalHeader, CModalTitle, CModalBody } from '@coreui/react-pro'
import Spinner from '../../../components/Spinner'
import { checkIfVideo, checkIfImage } from '../../../utils'

interface ViewFileModalProps {
  onClose: () => void
  fileUrl: string
}

/**
 * @function ViewFileModal
 * @props {ViewFileModalProps} onClose - The function to call when the modal is closed.
 * @props {string} fileUrl - The URL of the file to view. (image, video, pdf, etc.)
 * @returns {React.FC} - The ViewFileModal component.
 */
const ViewFileModal: React.FC<ViewFileModalProps> = ({ onClose, fileUrl }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isFileVideo, setIsFileVideo] = useState(false)
  const [isFileImage, setIsFileImage] = useState(false)

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const [isVideo, isImage] = await Promise.all([
        checkIfVideo(fileUrl),
        checkIfImage(fileUrl)
      ])
      setIsFileVideo(isVideo)
      setIsFileImage(isImage)
      setIsLoading(false)
    })()
  }, [fileUrl])

  if (isLoading) {
    return (
      <CModal visible alignment="center" size="lg">
        <Spinner />
      </CModal>
    )
  }

  return (
    <CModal visible={true} onClose={onClose} size="xl">
      <CModalHeader>
        <CModalTitle>File Viewer</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {fileUrl && (
          isFileVideo ? (
            <video
              src={fileUrl}
              style={{ width: '100%', height: 'auto' }}
              controls
              controlsList="nodownload"
            />
          ) : isFileImage ? (
            <img
              src={fileUrl}
              style={{ width: '100%', height: 'auto' }}
              alt="File preview"
            />
          ) : (
            <iframe
              src={`${fileUrl}#toolbar=0`}
              style={{
                width: '100%',
                height: '80vh',
                border: 'none'
              }}
              title="File preview"
            />
          )
        )}
      </CModalBody>
    </CModal>
  )
}

export default ViewFileModal