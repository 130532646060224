const checkIfVideo = (url: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const video = document.createElement("video")
    video.src = url
    video.onloadeddata = () => resolve(true)
    video.onerror = () => resolve(false)
  })
}
const checkIfImage = (url: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const img = new Image()
    img.src = url
    img.onload = () => resolve(true)
    img.onerror = () => resolve(false)
  })
}
export { checkIfVideo, checkIfImage }